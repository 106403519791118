var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui-grid-layout',[_c('template',{slot:"h1"},[_vm._v("Playlists")]),_c('template',{slot:"h2"},[_vm._v("Hier finden Sie von der dpa-Redaktion erstellte Sammlungen von Tönen zu aktuellen und zeitlosen Themen. ")]),_c('template',{slot:"gridContent"},[(_vm.publicPlaylist.length && _vm.isUserView)?_vm._l((_vm.publicPlaylist),function(ref,index){
var label = ref.label;
var src = ref.src;
var id = ref.id;
return _c('ui-image-card',{key:("playlist-" + index),staticClass:"overflow-hidden cursor-pointer",attrs:{"img-url":src,"title":label,"layout":"list"},nativeOn:{"click":function($event){_vm.onCardClick(id);
            _vm.useMatomoEvent({
              category: 'Playlist',
              action: 'Click Playlist card',
              name: ("Playlist Page (" + label + ")")
            });}}})}):_vm._e()],2),_c('template',{slot:"content"},[(_vm.playlists.length && _vm.isAdminView)?[_c('draggable',{ref:"draggableRef",staticClass:"grid gap-6 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3",attrs:{"force-fallback":_vm.isSafari,"list":_vm.playlists,"options":{ animation: 200 },"ghost-class":"opacity-60","element":'div'},on:{"change":_vm.updatePlaylistOrder}},_vm._l((_vm.playlists),function(ref,index){
            var label = ref.label;
            var src = ref.src;
            var id = ref.id;
            var isPlaylistActive = ref.isPlaylistActive;
return _c('div',{key:("playlist-admin-" + index),nativeOn:{"mouseover":function($event){_vm.showByIndex = index},"mouseleave":function($event){_vm.showByIndex = undefined}}},[_c('ui-image-card',{staticClass:"overflow-hidden cursor-move",attrs:{"img-url":src,"title":label,"layout":"list","badge":_vm.showBadge(isPlaylistActive)},nativeOn:{"click":function($event){_vm.onCardClick(id);
                _vm.useMatomoEvent({
                  category: 'Playlist',
                  action: 'Click Playlist card',
                  name: ("Playlist Page (" + label + ")")
                });}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdminView && _vm.showByIndex === index),expression:"isAdminView && showByIndex === index"}],staticClass:"absolute left-auto z-30 hidden right-2 top-4 md:block"},[_c('a-h-playlist-action-menu',{staticClass:"rounded-full focus:outline-none",attrs:{"playlist":{ label: label, src: src, id: id, isPlaylistActive: isPlaylistActive }},on:{"option-select":_vm.onPlaylistOptionSelected}})],1)])],1)}),0)]:_vm._e()],2)],2),_c('ui-transition-opacity',[(_vm.editPlaylist.showDialog)?_c('a-h-edit-playlist-dialog',{attrs:{"playlist-id":_vm.editPlaylist.playlistId},on:{"close":function($event){_vm.editPlaylist.showDialog = false;
        _vm.editPlaylist.playlistId = '';}}}):_vm._e()],1),_c('ui-transition-opacity',[_c('a-h-delete-playlist-dialog',{attrs:{"show":_vm.deletePlaylist.showDialog,"playlist-id":_vm.deletePlaylist.playlistId,"portal-target-name":"deletePlaylist"},on:{"close":function($event){_vm.deletePlaylist.showDialog = false;
        _vm.deletePlaylist.playlistId = '';}}})],1),_c('portal-target',{attrs:{"name":"deletePlaylist"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }