










































































































import {
  computed,
  defineComponent,
  ref,
  reactive,
  inject
} from "@/composition-api";
import UiGridLayout from "@/components/ui/UiGridLayout.vue";
import UiImageCard from "@/components/ui/UiImageCard.vue";
import useFetchPlaylists from "@/composition/useFetchPlaylists";
import useGetUserRole from "@/composition/useGetUserRole";
import AHPlaylistActionMenu from "./AHPlaylistActionMenu.vue";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import AHEditPlaylistDialog from "./AHEditPlaylistDialog.vue";
import AHDeletePlaylistDialog from "./AHDeletePlaylistDialog.vue";
import useManagePlaylists from "@/composition/useManagePlaylists";
import useMatomoEvent from "@/composition/useMatomoEvent";
import { deletePlaylist } from "./playlistState";
import draggable from "vuedraggable";
import store from "@/store";
import useAuthentication from "@/composition/useAuthentication";
import { isSafari } from "@/composition/useDraggable";

export default defineComponent({
  components: {
    UiGridLayout,
    UiImageCard,
    AHPlaylistActionMenu,
    UiTransitionOpacity,
    AHEditPlaylistDialog,
    AHDeletePlaylistDialog,
    draggable
  },
  props: {},
  setup: function() {
    const eventBus: Vue = inject("eventBus");
    const { validateAndRetrieveToken } = useAuthentication();
    const authJWT = validateAndRetrieveToken();
    const {
      fetchPlaylists,
      playlists,
      publicPlaylist,
      onCardClick,
      orderPublicPlaylist
    } = useFetchPlaylists();

    fetchPlaylists();
    const { isUser, isAdmin, isEditor } = useGetUserRole();
    const isUserView = computed(
      () => isUser.value && !isAdmin.value && !isEditor.value
    );
    const isAdminView = computed(() => isAdmin.value || isEditor.value);
    const showByIndex = ref();
    const editPlaylist = reactive({
      showDialog: false,
      playlistId: ""
    });
    const managePlaylists = useManagePlaylists();

    // User view
    const publicPlaylistSorted = computed(() => {
      return orderPublicPlaylist(publicPlaylist);
    });

    const onPlaylistOptionSelected = ({ value, playlist }) => {
      switch (value) {
        case "edit":
          editPlaylist.showDialog = true;
          editPlaylist.playlistId = playlist.id;
          break;
        case "publish":
          if (!playlist.isPlaylistActive) {
            managePlaylists.updatePlaylist({
              id: playlist.id,
              isPlaylistActive: true
            });
          }
          eventBus.$emit("show", {
            title: `Playlist veröffentlicht`,
            icon: "checkmark"
          });
          break;
        case "delete":
          deletePlaylist.showDialog = true;
          deletePlaylist.playlistId = playlist.id;
          break;
        default:
          break;
      }
    };

    const draggableRef = ref(null);

    const updatePlaylistOrder = () => {
      try {
        playlists.value.forEach((_, index) => {
          store.dispatch("playlists/updatePlaylistOrder", {
            playlist: {
              ...playlists.value[index],
              order: index
            },
            authJWT
          });
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const showBadge = isPublished => {
      if (isPublished && isAdminView.value) {
        return "veröffentlicht";
      } else if (!isPublished && isAdminView.value) {
        return "entwurf";
      }
    };

    return {
      publicPlaylistSorted,
      playlists,
      publicPlaylist,
      isUserView,
      isAdminView,
      isAdmin,
      isEditor,
      isUser,
      onCardClick,
      showByIndex,
      onPlaylistOptionSelected,
      editPlaylist,
      deletePlaylist,
      useMatomoEvent,
      showBadge,
      draggableRef,
      updatePlaylistOrder,
      isSafari
    };
  }
});
